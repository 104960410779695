<template>
  <div class="wrapper px-2" data-testid="smartSearch">
    <SmartSearch
      :dense="true"
      :disabled="isLoading"
      :fetch="fetchSuggestions"
      history-prefix="CASE_DETAIL_SEARCH"
      :placeholder="$t('App.Bar.InCaseSearch.inputPlaceholder')"
      @clear="onClear"
      @searched="search"
    />
  </div>
</template>

<script lang="ts">
import dayjs from 'dayjs';
import { defineComponent } from 'vue';

import SmartSearch from '@/case-detail/search/app-bar/components/SmartSearch.vue';
import searchService, { SearchSuggestion } from '@/case-detail/search/services/search.service';
import { findDocumentIds } from '@/case-detail/search/services/suggestions.helper';
import detailViewService from '@/case-detail/services/detail.view.service';
import documentService, { DocumentDiagnosis } from '@/case-detail/subviews/document/services/document.service';
import documentFilterService from '@/case-detail/subviews/documents-list/services/filter/document.filter.service';
import { DocumentFilterTypes } from '@/case-detail/subviews/documents-list/services/filter/document.filters';
import labelService from '@/case-detail/subviews/labels/services/label.service';
import { broadcastEventBus } from '@/common/services/broadcast.service';
import { SearchFieldKey } from '@/common/services/entity.service';
import logger from '@/common/services/logging';

export default defineComponent({
  components: { SmartSearch },

  data() {
    return {
      FILTERS_PROPS: {
        ...documentFilterService.FILTERS,
        searchTerm: {
          key: 'searchTerm',
          default: '',
          isDefault: (value: string) => value.length === 0,
          icon: () => 'mdi-magnify',
        },
      },
      FIELD_SUGGESTIONS_LIMIT: 3,
      FULLTEXT_SUGGESTIONS_LIMIT: 5,
    };
  },

  computed: {
    isLoading(): boolean {
      return documentService.isLoading();
    },
    caseId(): string | null {
      return detailViewService.getCurrentLegalCaseId();
    },
  },

  methods: {
    async fetchSuggestions(query: string, fieldKey: SearchFieldKey): Promise<SearchSuggestion[]> {
      const filteredDocuments = documentService.getFilteredDocuments();
      switch (fieldKey) {
        case 'fulltext': {
          if (this.caseId) {
            return await searchService.getFullTextSuggestions(this.caseId, query, this.FULLTEXT_SUGGESTIONS_LIMIT);
          }
          return [];
        }
        case 'labels': {
          return searchService.getLabelsSuggestions(labelService.legalCaseLabels(), query, this.FIELD_SUGGESTIONS_LIMIT);
        }
        case 'diagnosis': {
          return searchService.getDiagnosisSuggestions(filteredDocuments, query, this.FIELD_SUGGESTIONS_LIMIT);
        }
        case 'title':
        case 'ref':
        case 'author':
        case 'authorInstitution':
        case 'recipient':
        case 'recipientInstitutions': {
          return searchService.getFieldSuggestions(
            filteredDocuments,
            fieldKey,
            this.FILTERS_PROPS[fieldKey].path,
            this.FILTERS_PROPS[fieldKey].compare,
            query,
            this.FIELD_SUGGESTIONS_LIMIT,
          );
        }
        case 'sourceFilePage': {
          return searchService.getFieldSuggestions(
            filteredDocuments,
            fieldKey,
            'sourceFilePage',
            (q, value) => value.toString() === q.toString(),
            query,
            this.FIELD_SUGGESTIONS_LIMIT,
          );
        }
        case 'issueDate': {
          return searchService.getIssueDatesSuggestions(filteredDocuments, query, this.FIELD_SUGGESTIONS_LIMIT);
        }
        default: {
          logger.warn(`No suggestions endpoint for field-key '${String(fieldKey)}'`);
          return [];
        }
      }
    },
    search(suggestion: SearchSuggestion): void {
      switch (suggestion.field) {
        case 'fulltext': {
          searchService.setSearchTerm(suggestion.suggestion);
          this.$a.l(this.$a.e.DOCLIST_SEARCH, {
            value: `${suggestion.field}:${suggestion.suggestion}`,
          });
          break;
        }
        case 'labels': {
          const labels = labelService.getLabelFamily(suggestion.id);
          const filters = {
            labels,
          };
          this.applyFilters(filters, suggestion.field, suggestion.suggestion);
          break;
        }
        case 'diagnosis': {
          const suggestionValue = suggestion.value as DocumentDiagnosis;
          const foundDocIds = findDocumentIds(documentService.getFilteredDocuments(), suggestionValue);
          const diagnosis = {
            code: suggestionValue.icd10Code,
            title: suggestionValue.title,
            documents: foundDocIds,
          };
          const filters = {
            diagnosis,
          };
          this.applyFilters(filters, suggestion.field, suggestion.suggestion);
          break;
        }
        case 'title':
        case 'ref':
        case 'author':
        case 'authorInstitution':
        case 'recipient':
        case 'recipientInstitutions': {
          const filters = {
            [suggestion.field]: suggestion.suggestion,
          };
          this.applyFilters(filters, suggestion.field, suggestion.suggestion);
          break;
        }
        case 'sourceFilePage': {
          const page = parseInt(suggestion.suggestion, 10);
          this.goToSourceFilePage(page);
          break;
        }
        case 'issueDate': {
          const suggestionValue = suggestion.value as string;
          const filters = {
            minDate: dayjs(suggestionValue).valueOf(),
            maxDate: dayjs(suggestionValue).endOf('day').valueOf(),
          };
          this.applyFilters(filters, suggestion.field, suggestion.suggestion);
          break;
        }
        default: {
          logger.warn(`No action for field-key <<${suggestion?.field ? String(suggestion.field) : ''}>>`);
        }
      }
    },
    async applyFilters(filters: Partial<DocumentFilterTypes>, fieldKey: SearchFieldKey, query: string): Promise<void> {
      documentFilterService.setFilters(filters);
      this.$a.l(this.$a.e.DOCLIST_SEARCH, {
        value: `${String(fieldKey)}:${query}`,
      });
    },
    goToSourceFilePage(sourceFilePage: number): void {
      // Find document
      for (const document of documentService.getFilteredDocuments()) {
        if (document.sourceFilePage === sourceFilePage) {
          broadcastEventBus.emit('DOCUMENT_SELECTED_EVENT', {
            docId: document.id,
            page: 1,
            scroll: true,
            forceShow: false,
            iterate: false,
          });
          return;
        }
      }
    },
    onClear(): void {
      searchService.clear();
    },
  },
});
</script>

<style scoped>
.wrapper {
  width: 100%;
  max-width: 450px;
}
</style>
