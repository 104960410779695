import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { reactive } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import detailViewService, { CurrentLegalCase } from '@/case-detail/services/detail.view.service';
import icd10Service from '@/case-detail/subviews/diagnosis/services/icd10.service';
import documentService, { type Document } from '@/case-detail/subviews/document/services/document.service';
import viewerService from '@/case-detail/subviews/document/services/viewer.service';
import logging from '@/common/services/logging';
import { API } from '@/common/types/api.types';
import { ISODateString, UUID } from '@/common/types/common.types';

export interface Diagnosis extends API.LegalCase.Diagnosis {
  title: string;
  computedQualifications: string[];
  computedCount: number;
  extractsByTag: Record<string, API.Diagnosis.Extract[]>;
  issueDate: Dayjs | null;
  issueDates: Dayjs[];
}

const sortingOptions = {
  RELEVANCE_DESC: {
    key: 'RELEVANCE_DESC',
    text: () => $t('CaseDetail.Diagnoses.sortingByRelevance'),
    icon: 'mdi-sort-descending',
    order: 'DESC',
  },
  DATE_ASC: {
    key: 'DATE_ASC',
    text: () => $t('CaseDetail.Diagnoses.sortingChronological'),
    icon: 'mdi-sort-calendar-ascending',
    order: 'ASC',
  },
};

interface LegalCaseDiagnosisWithTitle extends API.LegalCase.Diagnosis {
  title: string;
}

interface ServiceState {
  diagnoses: LegalCaseDiagnosisWithTitle[];
  extracts: API.Diagnosis.Extract[];
  diagnosisInfoPanel: {
    open: boolean;
    selectedDiagnosis: ReturnType<typeof icd10Service.getDiagnosis> | null;
  };
  search: {
    tags: string[];
    query: string | null;
  };
  sorting: keyof typeof sortingOptions;
}

class DiagnosisService {
  initPromise: null | Promise<void>;

  sortingOptions = sortingOptions;

  state: ServiceState;

  constructor() {
    this.initPromise = null;

    this.state = reactive({
      diagnoses: [],
      extracts: [],
      diagnosisInfoPanel: {
        open: false,
        selectedDiagnosis: null,
      },
      search: {
        tags: [],
        query: null,
      },
      sorting: 'RELEVANCE_DESC',
    });
  }

  async init() {
    if (!this.initPromise) {
      this.initPromise = this.doInit();
    }
    return this.initPromise;
  }

  async doInit() {
    const legalCase: CurrentLegalCase | null = detailViewService.getCurrentLegalCase();
    if (!legalCase) {
      logging.error('Can not init diagnosis service, no current legal case');
      return;
    }
    this.state.diagnoses = legalCase.diagnoses.map((d) => ({ ...d, title: icd10Service.getDiagnosis(d.code)?.title ?? '' }));
    this.state.extracts = await this.fetchExtracts();
  }

  getDiagnoses(documents: Document[] | null): Diagnosis[] {
    const { search } = this.state;
    let stateDiagnoses = this.state.diagnoses;

    if (search.query) {
      stateDiagnoses = stateDiagnoses.filter(
        (d) => d.code.toLowerCase().includes(search.query!.toLowerCase()) || d.title.toLowerCase().includes(search.query!.toLowerCase()),
      );
    }

    let diagnoses: Diagnosis[] = stateDiagnoses.map((d) => {
      const extracts = this.getExtractsByCode(d.code);
      const tags = this.getTagsForExtracts(extracts);
      const uncountableTags = this.getUncountableTags(d.qualifications);
      const dates = this.getIssueDates(extracts, documents);

      return {
        ...d,
        computedQualifications: [...tags.tags, ...uncountableTags],
        computedCount: extracts.length,
        extractsByTag: tags.extractsByTag,
        issueDate: dates.min,
        issueDates: dates.all,
      };
    });

    if (search.tags.length > 0) {
      diagnoses = diagnoses.filter((d) => search.tags.every((v) => d.computedQualifications.includes(v)));
    }

    // remove invalid (i.e., without extracts)
    diagnoses = diagnoses.filter((d) => d.count > 0);

    // remove invalid (i.e., without extracts)
    diagnoses = diagnoses.filter((d) => d.computedCount > 0);

    if (this.state.sorting.startsWith('RELEVANCE')) {
      diagnoses.sort(this.sortDiagnosesByRelevance);
    } else if (this.state.sorting.startsWith('DATE')) {
      diagnoses.sort(this.sortDiagnosesByDate);
    }
    if (this.sortingOptions[this.state.sorting].order === 'DESC') {
      diagnoses.reverse();
    }
    return diagnoses;
  }

  setSorting(key: string) {
    if (key in this.sortingOptions) {
      this.state.sorting = key as keyof typeof sortingOptions;
    }
  }

  getSortingOptions() {
    return Object.values(this.sortingOptions);
  }

  getSortingOption(key: string) {
    if (key in this.sortingOptions) {
      return this.sortingOptions[key as keyof typeof sortingOptions];
    }
    return null;
  }

  // sorting functions should by default sort ASC
  sortDiagnosesByRelevance(a: Diagnosis, b: Diagnosis) {
    if (a.confidence !== b.confidence) {
      return a.confidence - b.confidence;
    }
    return a.count - b.count;
  }

  sortDiagnosesByDate(a: Diagnosis, b: Diagnosis) {
    return dayjs(a.issueDate).isAfter(dayjs(b.issueDate)) ? 1 : -1;
  }

  getExtractsByCode(code: string) {
    // NOTE(kt) we need to filter out duplicates, matched by sourcefileId and page
    return this.state.extracts.filter(
      (e) =>
        e.code === code &&
        !documentService
          .getDuplicates()
          .some((d) => e.sourceFileId === d.sourceFileId && e.sourceFilePage >= d.sourceFilePage && e.sourceFilePage <= d.sourceFileEndPage),
    );
  }

  getTagsForExtracts(extracts: API.Diagnosis.Extract[]) {
    const tags = new Set<string>();
    const extractsByTag: Record<string, API.Diagnosis.Extract[]> = {};
    for (const extract of extracts) {
      for (const tag of extract.tags) {
        // add tag to set
        tags.add(tag);
        // add extract
        if (tag in extractsByTag) {
          extractsByTag[tag] = [...extractsByTag[tag], extract];
        } else {
          extractsByTag[tag] = [extract];
        }
      }
    }

    return {
      tags: [...tags],
      extractsByTag,
    };
  }

  getUncountableTags(tags: Record<string, number>) {
    const hiddenTags = icd10Service.getHiddenTags();
    const result = [];
    for (const hiddenTag of hiddenTags) {
      if (hiddenTag.key in tags) {
        result.push(hiddenTag.key);
      }
    }
    return result;
  }

  getIssueDates(extracts: API.Diagnosis.Extract[], documents: Document[] | null) {
    if (extracts.length === 0 || documents === null) return { all: [], min: null };
    const dateListForDiagnose = [];
    for (const extract of extracts) {
      // Note(ndv): each extract has a 'issueDate' property, but since users can change the issue dates, we need to compute them on the fly
      for (const document of documents) {
        if (
          extract.sourceFileId === document.sourceFileId &&
          document.sourceFilePage <= extract.sourceFilePage &&
          extract.sourceFilePage <= document.sourceFileEndPage &&
          document.metadata.ISSUE_DATE.value
        ) {
          dateListForDiagnose.push(dayjs(document.metadata.ISSUE_DATE.value));
        }
      }
    }
    if (dateListForDiagnose.length > 0) {
      return { all: dateListForDiagnose, min: dayjs.min(dateListForDiagnose) };
    }

    return { all: [], min: null };
  }

  displayInfo(diagnosis: ReturnType<typeof icd10Service.getDiagnosis> | null) {
    if (!diagnosis || diagnosis?.code === this.state.diagnosisInfoPanel.selectedDiagnosis?.code) {
      this.state.diagnosisInfoPanel.selectedDiagnosis = null;
      this.state.diagnosisInfoPanel.open = false;
    } else {
      this.state.diagnosisInfoPanel.selectedDiagnosis = diagnosis;
      this.state.diagnosisInfoPanel.open = true;
    }
  }

  async fetchExtracts(): Promise<API.Diagnosis.Extract[]> {
    try {
      const legalCaseId: UUID = detailViewService.getCurrentLegalCaseId();
      const response = await axios.get(`${config.API.DIAGNOSIS_EXTRACT_ENDPOINT.replace('{legalCaseId}', legalCaseId)}`);
      return response.data;
    } catch (e) {
      handleError($t('CaseDetail.Diagnoses.diagnosesLoadError'), e);
      return [];
    }
  }

  findDocumentIds(documents: Document[], diagnosis: Diagnosis, tag: string | null) {
    const documentIds = [];
    const extracts = tag && tag in diagnosis.extractsByTag ? diagnosis.extractsByTag[tag] : this.getExtractsByCode(diagnosis.code);

    for (const document of documents) {
      for (const extract of extracts) {
        if (
          extract.sourceFileId === document.sourceFileId &&
          document.sourceFilePage <= extract.sourceFilePage &&
          extract.sourceFilePage <= document.sourceFileEndPage
        ) {
          documentIds.push({
            id: document.id,
            page: extract.sourceFilePage - document.sourceFilePage + 1,
          });
        }
      }
    }

    return documentIds;
  }

  async updateDiagnosisRelevancy(diagnosisId: UUID, relevancy: boolean) {
    const diagnosis = this.state.diagnoses.find((d) => d.id === diagnosisId);
    if (!diagnosis) {
      return new Promise(() => {});
    }
    // update state
    diagnosis.relevant = relevancy;

    // persist
    const data = {
      relevant: relevancy,
    };
    return axios
      .patch(config.API.CASE_DIAGNOSIS_ENDPOINT.replace('{legalCaseId}', detailViewService.getCurrentLegalCaseId()) + diagnosisId, data)
      .then(() => {
        appService.info($t('CaseDetail.Diagnoses.saved'));
      })
      .catch((e) => handleError($t('CaseDetail.Diagnoses.diagnosesSaveError'), e));
  }

  // TODO(LEG-3290): to be refactored
  async createDiagnosisExtract(options: {
    sourceFileId: UUID;
    sourceFilePage: number;
    icd10Code: string;
    title: string;
    coveredText: string;
    tags: string[];
    issueDate: ISODateString;
    xfdf: string;
  }) {
    const { sourceFileId, sourceFilePage, icd10Code, title, coveredText, tags, issueDate, xfdf } = options;
    const extractProperties = {
      conceptId: icd10Code,
      matchedTerm: title,
      coveredText, // TODO(ndv): doubt this is needed, remove?
      tags,
      issueDate, // TODO(LEG-3290): this makes no sense and needs to be refactored
    };

    const createExtractRequest = {
      sourceFileId,
      sourceFilePage,
      xfdf,
      properties: {
        ...extractProperties,
      },
    };

    const response = await axios.put(this.getDiagnosisExtractURL(), createExtractRequest).catch((e) => {
      handleError($t('CaseDetail.Diagnoses.changesSaveError'), e);
      return null;
    });
    if (response === null) {
      return null;
    }

    this.state.extracts.push(response.data as API.Diagnosis.Extract);
    return response.data as API.Diagnosis.Extract;
  }

  // TODO(LEG-3290): to be refactored
  async updateDiagnosisExtract(extractId: UUID, diagnosisCode: string, diagnosisTitle: string, diagnosisTags: string[]) {
    const extract = this.state.extracts.find((e) => e.id === extractId);
    if (!extract) {
      return;
    }
    extract.code = diagnosisCode;
    extract.title = diagnosisTitle;
    extract.tags = diagnosisTags;

    extract.annotation = extract.annotation.replaceAll(/<contents>.+<\/contents>/g, `<contents>${diagnosisTitle} (${diagnosisCode})</contents>`);

    extract.properties = {
      ...extract.properties,
      tags: diagnosisTags ?? extract.properties.tags,
      manualConceptId: diagnosisCode,
      manualMatchedTerm: diagnosisTitle,
    };

    // update state
    const currentDocument = documentService.getDocumentsCache().get(viewerService.getDocumentId())!;

    const response = await axios.patch(this.getDiagnosisExtractURL(extract.id), extract).catch((e) => {
      handleError($t('CaseDetail.Diagnoses.changesSaveError'), e);
    });
    if (!response) return;

    const updatedExtract = response.data as API.Diagnosis.Extract;

    // update diagnosis in documents cache
    for (const diagnosis of currentDocument.diagnoses) {
      if (diagnosis.id === updatedExtract.id) {
        diagnosis.icd10Code = updatedExtract.code;
        diagnosis.title = updatedExtract.title;
        diagnosis.tags = updatedExtract.tags;
      }
    }
    appService.info($t('CaseDetail.Diagnoses.changesSaved'));
  }

  // TODO(ndv): to be refactored
  deleteDiagnosisExtract(extractId: UUID) {
    const extract = this.state.extracts.find((e) => e.id === extractId);
    if (!extract) {
      return;
    }
    // update state (extracts)
    const newExtracts = this.state.extracts.filter((e) => e.id !== extractId);
    this.state.extracts = newExtracts;

    // update document
    const currentDocument = documentService.getDocumentsCache().get(viewerService.getDocumentId())!;
    currentDocument.diagnoses = currentDocument.diagnoses.filter((d) => d.id !== extractId);

    // persist
    axios.delete(this.getDiagnosisExtractURL(extractId)).catch((e) => {
      handleError($t('CaseDetail.Diagnoses.changesSaveError'), e);
    });

    // if there is no more extracts with the same code, remove the legal case diagnosis from the state
    const anyOtherWithSameCode = newExtracts.filter((e) => e.code === extract.code);
    if (anyOtherWithSameCode.length === 0) {
      // update state (case diagnoses)
      this.state.diagnoses = this.state.diagnoses.filter((d) => d.code !== extract.code);
    }
  }

  getDiagnosisExtractURL(extractId?: UUID) {
    let url = config.API.DIAGNOSIS_EXTRACT_ENDPOINT.replace('{legalCaseId}', detailViewService.getCurrentLegalCaseId());
    if (extractId) {
      url += `/${extractId}`;
    }

    return url;
  }

  clear() {
    this.initPromise = null;
    this.state.diagnoses = [];
    this.state.search = {
      tags: [],
      query: null,
    };
    this.state.diagnosisInfoPanel = {
      open: false,
      selectedDiagnosis: null,
    };
  }
}

export default new DiagnosisService();
export const DiagnosisServiceClass = DiagnosisService;
