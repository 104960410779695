import { cloneDeep } from 'lodash';
import { reactive } from 'vue';

import documentService, { Document } from '@/case-detail/subviews/document/services/document.service';
import { DocumentSortMode, sortModes } from '@/case-detail/subviews/documents-list/services/sort/document.sort.modes';
import preferencesService from '@/common/services/preferences.service';

interface ServiceState {
  sorting: DocumentSortMode;
}

const initialState: ServiceState = {
  sorting: 'ISSUE_DATE_ASC',
};

class DocumentSortService {
  state: ServiceState;

  SORT_MODES = sortModes;

  constructor() {
    this.state = reactive(cloneDeep(initialState));
  }

  get currentSorting() {
    return this.state.sorting;
  }

  loadSavedSorting() {
    this.state.sorting = preferencesService.state.caseDetailViewPreferences.listSortMode as DocumentSortMode;
  }

  setSorting(sorting: DocumentSortMode, temporary = false) {
    if (!temporary) {
      preferencesService.updatePreferences({ caseDetailViewPreferences: { listSortMode: sorting } });
    }
    this.state.sorting = sorting;
    documentService.updateFilteredDocuments();
  }

  getSortedDocuments(documents: Document[]) {
    return documents.slice().sort((a, b) => this.sortFn(a, b));
  }

  sortFn(a: Document, b: Document) {
    const { sortFn } = sortModes[this.state.sorting];
    return sortFn(a, b);
  }
}

export default new DocumentSortService();
export const DocumentSortServiceClass = DocumentSortService;
