<template>
  <v-card class="outlined" flat data-testid="case_card">
    <v-card-text class="pt-2 pb-0 mb-0 card-text">
      <div class="d-flex text-caption mb-1 text-medium-emphasis">
        <div class="mr-auto text-truncate">
          <tippy :content="$t('CaseList.CaseTile.caseReference')" placement="top">
            <span data-testid="case_reference">
              {{ legalCase.reference }}
            </span>
            <span v-if="legalCase.pii.CUSTOM_1.value"
              >,
              {{ legalCase.pii.CUSTOM_1.value }}
            </span>
          </tippy>
        </div>

        <div
          v-if="legalCase.updated"
          v-tippy="{ content: $t('CaseList.CaseTile.lastUpdateDate'), placement: 'top' }"
          class="text-right text-no-wrap ml-1"
          data-testid="case_lastUpdate"
        >
          <span data-testid="case_reference">
            {{ formatToLocale(legalCase.updated) }}
          </span>
        </div>
      </div>

      <div class="text-h5 pb-0 mb-1 pt-1 on-surface data-hj-suppress tooltip-wrapper" data-testid="case_title">
        <div v-tippy="{ content: legalCase.displayLabel, placement: 'left' }" class="tooltip-overflow-ellipsis">
          <span>{{ legalCase.displayLabel }}</span>
        </div>
      </div>

      <div
        v-if="legalCase.pii.PII_COMPANY.value !== '' && legalCase.pii.PII_COMPANY.value !== legalCase.displayLabel"
        class="text-medium-emphasis tooltip-wrapper"
      >
        <div v-tippy="{ content: $t('Common.customerCompany'), placement: 'left' }" class="tooltip-overflow-ellipsis">
          <span data-testid="case_company">
            {{ legalCase.pii.PII_COMPANY.value }}
          </span>
        </div>
      </div>

      <div class="text-medium-emphasis tooltip-wrapper">
        <div v-tippy="{ content: $t('Common.docFolder'), placement: 'left' }" class="tooltip-overflow-ellipsis">
          <span data-testid="case_folders">
            {{ getDescription(legalCase.folders) }}
          </span>
        </div>
      </div>

      <div class="text-medium-emphasis tooltip-wrapper">
        <div v-tippy="{ content: $t('CaseList.CaseTile.responsiblePerson'), placement: 'left' }" class="tooltip-overflow-ellipsis">
          <span data-testid="case_owner">
            {{ getFullName(legalCase.owner) }}
          </span>
        </div>

        <tippy v-if="legalCase.accessGroup">
          <v-chip size="x-small" class="ml-2 mt-0 pt-0">
            <v-icon size="small"> mdi-key </v-icon>
          </v-chip>
          <template #content>
            <span>{{ $t('CaseList.CaseTile.accessGroup') }}<br />{{ legalCase.accessGroup }}</span>
          </template>
        </tippy>

        <v-chip
          v-if="$hasPermission('ACCESS_CASE_DETAIL_LEGALCASE_ACCESS') && hasValidLegalCaseAccess"
          v-tippy="$t('CaseList.CaseTile.atLeastOneExternalUserHasAccess')"
          size="x-small"
          class="ml-2 mt-0 pt-0"
        >
          <v-icon size="small"> mdi-account-key </v-icon>
        </v-chip>

        <v-chip v-if="withNewDocs" v-tippy="$t('CaseList.CaseTile.caseHasNewDocs')" size="x-small" class="ml-2 mt-0 pt-0">
          <v-icon size="small"> mdi-newspaper </v-icon>
        </v-chip>
        <v-chip v-if="ownCase" v-tippy="$t('CaseList.CaseTile.ownCase')" size="x-small" class="ml-2 mt-0 pt-0" color="primary">
          <v-icon size="small"> mdi-account-outline </v-icon>
        </v-chip>

        <v-chip
          v-if="$hasFeature('ENABLE_INACTIVITY_CHECK') && isInctive"
          v-tippy="$t('CaseList.CaseTile.lastActivity', [formatToLocale(legalCase.lastOwnerActivity)])"
          data-testid="case_inactive"
          size="x-small"
          class="ml-2 mt-0 pt-0"
        >
          <v-icon size="small"> mdi-clock-alert </v-icon>
        </v-chip>
      </div>
    </v-card-text>

    <!-- OPEN-->
    <v-card-actions v-if="legalCase.legalCaseStatus === 'OPEN'" class="pl-1-rem pr-1-rem">
      <!-- is admin and in review -->
      <template v-if="$isLegaliAdmin() && legalCase.pipelineStatus === 'CASE_REVIEW'">
        <v-btn
          color="primary"
          variant="outlined"
          :to="{
            name: 'case',
            params: { tenant: tenant.canonicalName, caseId: legalCase.id },
          }"
        >
          {{ $t('CaseList.CaseTile.review') }}
        </v-btn>
        <v-btn
          color="accent"
          variant="flat"
          @click="
            pipelineOrchestratorService.reviewTaskFinished(legalCase.id);
            legalCase.pipelineStatus = null;
          "
        >
          {{ $t('Common.share') }}
        </v-btn>
      </template>

      <v-btn v-else-if="!legalCase.sourceFileIds.length" disabled variant="text">
        {{ $t('CaseList.CaseTile.noFiles') }}
      </v-btn>

      <tippy v-else-if="legalCase.ready && legalCase.inProgress">
        <v-btn color="gray" variant="outlined" :to="defaultCaseView">
          {{ $t('Common.openCase') }}
        </v-btn>
        <template #content>
          {{ $t('CaseList.CaseTile.caseIsBeingProcessedTooltip') }}
        </template>
      </tippy>

      <v-btn
        v-else-if="legalCase.ready && legalCase.overDocumentCountLimit"
        v-tippy="$t('CaseDetail.tooManyDocsPerformanceLimited')"
        color="primary"
        :to="defaultCaseView"
        variant="flat"
      >
        <v-icon class="mr-1"> mdi-alert </v-icon>{{ $t('Common.openCase') }}
      </v-btn>

      <v-btn v-else-if="legalCase.ready" color="primary" :to="defaultCaseView" variant="flat" data-testid="action_caseOpen">
        {{ $t('Common.openCase') }}
      </v-btn>

      <v-btn v-else disabled variant="text">
        {{ $t('CaseList.processing') }}
      </v-btn>

      <v-spacer />

      <v-btn v-blur v-tippy="$t('Common.pin')" :icon="pinIcon" density="compact" data-testid="action_pinCase" @click="togglePinCase" />

      <v-btn
        v-if="$hasPermission('SOURCEFILE_RUD')"
        v-blur
        v-tippy="$t('CaseList.CaseTile.files')"
        density="compact"
        icon="mdi-folder-open-outline"
        data-testid="action_caseShowFiles"
        @click.stop="$emit('show-file-manager', legalCase)"
      />

      <l-menu location="bottom start">
        <template #activator="{ props: menuProps }">
          <v-badge
            :color="legalCase.pipelineStatus === 'SOURCEFILE_REVIEW' ? 'accent' : 'error'"
            :model-value="$isLegaliAdmin() && (legalCase.pipelineStatus === 'ERROR' || legalCase.pipelineStatus === 'SOURCEFILE_REVIEW')"
          >
            <template #badge>
              <v-icon v-if="legalCase.pipelineStatus === 'SOURCEFILE_REVIEW'" size="x-small"> mdi-circle-edit-outline </v-icon>
              <v-icon v-if="legalCase.pipelineStatus === 'ERROR'" size="x-small"> mdi-alert-circle-outline </v-icon>
            </template>

            <div>
              <v-btn
                v-if="!$isExternalUser()"
                v-tippy="$t('Common.moreActions')"
                icon="mdi-dots-vertical"
                density="compact"
                data-testid="case_actions"
                v-bind="menuProps"
              />
            </div>
          </v-badge>
        </template>
        <v-list density="compact" class="pa-0">
          <template v-if="$hasPermission('SOURCEFILE_RUD')">
            <v-list-item data-testid="action_showFiles" @click="$emit('show-file-manager', legalCase)">
              <v-list-item-title>{{ $t('CaseList.CaseTile.filesInThisCase') }}</v-list-item-title>
            </v-list-item>
          </template>

          <template v-if="$hasPermission('LEGALCASE_UD') || $hasPermission('LEGALCASE_U_OWNER_META')">
            <v-list-item data-testid="action_editCase" @click="$emit('show-edit-case-dialog', legalCase)">
              <v-list-item-title>{{ $t('CaseList.CaseTile.editCaseData') }}</v-list-item-title>
            </v-list-item>
          </template>

          <template v-if="$hasPermission('LEGALCASE_UD')">
            <v-list-item
              data-testid="action_changeCaseOwner"
              @click="
                appService.confirm(
                  $t('CaseDetail.changeOwnerConfirmationTitle'),
                  $t('CaseDetail.changeOwnerConfirmationMessage'),
                  $t('CaseDetail.changeOwnerConfirmationAction'),
                  changeOwner,
                )
              "
            >
              <v-list-item-title>{{ $t('CaseList.CaseTile.changeOwner') }}</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item
            v-if="$hasPermission('LEGALCASE_U_CLOSE')"
            data-testid="action_closeCase"
            @click="
              appService.confirm(
                $t('CaseDetail.closeConfirmationTitle'),
                $t('CaseDetail.closeConfirmationMessage', [legalCase.displayLabel]),
                $t('CaseDetail.closeConfirmationAction'),
                archiveCase,
                'primary',
              )
            "
          >
            <v-list-item-title>{{ $t('CaseDetail.closeConfirmationAction') }}</v-list-item-title>
          </v-list-item>
          <v-divider />
          <template v-if="$hasFeature('ENABLE_DEMO_FEATURES')">
            <v-list-item @click="findSimilarCases(legalCase)">
              <v-list-item-title>{{ $t('CaseList.CaseTile.findSimilarCases') }}</v-list-item-title>
            </v-list-item>
            <v-divider />
          </template>
          <template v-if="$isLegaliAdmin()">
            <div class="v-list-subheader v-list-subheader__text d-flex mt-2">
              <div>legal-i Admin</div>
              <ChipAdmin class="ml-1" />
            </div>
            <v-list-item v-if="!transferService.isInProgress" data-testid="action_export" @click="exportSingleLegalCase(legalCase)">
              <v-list-item-title>{{ $t('CaseList.CaseTile.exportCase') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="showAuditLog(legalCase)">
              <v-list-item-title>{{ $t('CaseList.CaseTile.viewAuditLog') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="$hasFeature('ENABLE_DATALABELING')"
              data-testid="action_showDataLabelDialog"
              @click="$emit('show-data-label-dialog', legalCase)"
            >
              <v-list-item-title>{{ $t('CaseList.manageLabeledData') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="$hasFeature('ENABLE_DEMO_FEATURES')"
              data-testid="action_editCase"
              @click="$emit('show-edit-demo-information-dialog', legalCase)"
            >
              <v-list-item-title>{{ $t('CaseList.editDemoData') }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </l-menu>
    </v-card-actions>

    <!-- ARCHIVED -->
    <v-card-actions v-else>
      <v-spacer />

      <v-btn
        v-tippy="$t('CaseDetail.reopenTooltip')"
        icon="mdi-archive-off-outline"
        density="compact"
        data-testid="action_unarchiveCase"
        @click="
          appService.confirm(
            $t('CaseDetail.reopenConfirmationTitle'),
            $t('CaseDetail.reopenConfirmationMessage', [legalCase.displayLabel]),
            $t('CaseDetail.reopenConfirmationAction'),
            unarchiveCase,
          )
        "
      />

      <v-btn
        v-tippy="$t('CaseDetail.deleteTooltip')"
        icon="mdi-delete-outline"
        data-testid="action_deleteCase"
        density="compact"
        color="error"
        @click="
          appService.confirm(
            $t('CaseDetail.deleteConfirmationTitle'),
            $t('CaseDetail.deleteConfirmationMessage', [legalCase.displayLabel]),
            $t('CaseDetail.deleteConfirmationAction'),
            deleteCase,
            'warning',
            legalCase.displayLabel,
          )
        "
      >
      </v-btn>
    </v-card-actions>
    <v-progress-linear v-if="legalCase.inProgress" absolute location="bottom" color="primary" indeterminate rounded />
  </v-card>
</template>

<script lang="ts">
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { defineComponent, PropType } from 'vue';

import appService from '@/app/services/app.service';
import { detailViewRouteService } from '@/case-detail/services/detail.view.route.service';
import { type LegalCase, legalCaseService } from '@/case-list/services/legalcase.service';
import { pipelineOrchestratorService } from '@/case-list/services/pipeline.orchestrator.service';
import transferService from '@/case-list/services/transfer.service';
import ChipAdmin from '@/common/components/ChipAdmin.vue';
import { authService } from '@/common/services/auth/auth.service';
import { formatToLocale } from '@/common/services/date.utils';
import { folderService } from '@/common/services/folder.service';
import preferencesService from '@/common/services/preferences.service';
import userService from '@/common/services/users/user.service';
import { API } from '@/common/types/api.types';

dayjs.extend(isSameOrAfter);
export default defineComponent({
  components: {
    ChipAdmin,
  },

  props: {
    legalCase: {
      type: Object as PropType<API.LegalCase.ListResponse>,
      required: true,
    },
  },

  emits: ['show-file-manager', 'show-edit-case-dialog', 'show-edit-demo-information-dialog', 'show-data-label-dialog'],

  data() {
    return {
      appService,
      transferService,
      pipelineOrchestratorService,
    };
  },

  computed: {
    defaultCaseView() {
      return detailViewRouteService.buildCaseRoute(this.legalCase.id)!;
    },

    tenant() {
      return authService.state.data!.tenant;
    },
    isCasePinned() {
      return preferencesService.state.caseListPreferences.pinnedCases.includes(this.legalCase.id);
    },
    hasValidLegalCaseAccess() {
      return dayjs(this.legalCase.legalCaseAccessUntil).isSameOrAfter(dayjs());
    },
    isInctive() {
      return !dayjs(this.legalCase.inactive).isSameOrAfter(dayjs());
    },
    pinIcon() {
      return this.isCasePinned ? 'mdi-pin' : 'mdi-pin-outline';
    },
    withNewDocs() {
      return dayjs(this.legalCase.lastOwnerActivity) < dayjs(this.legalCase.lastSourceFileUploadDate);
    },
    ownCase() {
      const owner = authService.state.userId;
      return this.legalCase.owner === owner;
    },
  },

  methods: {
    formatToLocale,
    getFullName(userId: string) {
      return userService.getFullName(userId);
    },
    getDescription(folders: string[] | null) {
      if (folders) {
        return folders
          .map((s) => {
            const k = folderService.getLocalizedFolders().find((a) => a.value === s);
            if (!k) return '';
            return k.title;
          })
          .join(', ');
      }
      return '';
    },
    deleteCase() {
      legalCaseService.deleteCase(this.legalCase.id);
      appService.info(this.$t('CaseList.CaseTile.caseDeletedMessage'));
      this.$a.l(this.$a.e.CASELIST_DELETE);
    },
    async changeOwner() {
      await legalCaseService.changeOwner(this.legalCase);
      appService.info(this.$t('CaseDetail.changeOwnerConfirmation'));
    },
    archiveCase() {
      legalCaseService.toggleArchiveCase(this.legalCase);
      appService.info(this.$t('CaseDetail.caseClosedMessage'));
      this.$a.l(this.$a.e.CASELIST_ARCHIVE);
    },
    unarchiveCase() {
      legalCaseService.toggleArchiveCase(this.legalCase);
      appService.info(this.$t('CaseList.CaseTile.caseReopenedMessage'));
      this.$a.l(this.$a.e.CASELIST_UNARCHIVE);
    },
    findSimilarCases(legalCase: LegalCase) {
      this.$router.push({
        name: 'similar',
        params: { tenant: this.tenant.canonicalName, caseId: legalCase.id },
      });
    },
    showAuditLog(legalCase: LegalCase) {
      this.$router.push({
        name: 'audit-log',
        params: { tenant: this.tenant.canonicalName },
        query: { q: `legalcase:${legalCase.id}` },
      });
      this.$a.l(this.$a.e.CASELIST_AUDIT);
    },
    togglePinCase() {
      const caseIndex = preferencesService.state.caseListPreferences.pinnedCases.indexOf(this.legalCase.id);
      if (caseIndex > -1) {
        preferencesService.state.caseListPreferences.pinnedCases.splice(caseIndex, 1);
      } else {
        const currentCaseIds = legalCaseService.state.cases.map((c) => c.id);

        const nonExistentIndices: number[] = [];

        // Find indices of non-existent cases
        preferencesService.state.caseListPreferences.pinnedCases.forEach((caseId, index) => {
          if (!currentCaseIds.includes(caseId)) {
            nonExistentIndices.push(index);
          }
        });

        // Remove non-existent cases in reverse order to not affect subsequent indices
        for (let i = nonExistentIndices.length - 1; i >= 0; i--) {
          preferencesService.state.caseListPreferences.pinnedCases.splice(nonExistentIndices[i], 1);
        }

        preferencesService.state.caseListPreferences.pinnedCases.push(this.legalCase.id);
      }
      preferencesService.savePreferences();
      this.$a.l(this.$a.e.CASELIST_PIN);
    },
    exportSingleLegalCase(legalCase: LegalCase) {
      const transferDto = {
        legalCaseIds: [legalCase.id],
      };
      this.transferService.exportLegalCase(transferDto);
      appService.info(this.$t('CaseList.CaseTile.caseWillBeExported', [legalCase.displayLabel]));
      this.$a.l(this.$a.e.CASELIST_EXPORT);
    },
  },
});
</script>

<style scoped>
.card-text {
  height: 123px;
}

.tooltip-wrapper {
  display: flex;
}
</style>
