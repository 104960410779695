import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

import DayJsAdapter from '@date-io/dayjs';
import { createVuetify } from 'vuetify';
import { de, en, fr, it } from 'vuetify/locale';

import { dark, light } from '@/app/themes';

export default createVuetify({
  icons: {
    defaultSet: 'mdi',
  },
  locale: {
    locale: 'de',
    fallback: 'de',
    messages: {
      de,
      en,
      fr,
      it,
    },
  },
  defaults: {
    global: { ripple: false },
    VTab: { ripple: true },

    VTextField: { variant: 'underlined' },
    VSelect: { variant: 'underlined' },
    VTextarea: { variant: 'underlined' },
    VCombobox: { variant: 'underlined' },
    VAutocomplete: { variant: 'underlined' },
    VFileInput: { variant: 'underlined' },
    VCheckbox: { color: 'primary' },
    VTooltip: {
      openDelay: 300, // to not see bunch of tooltips on scroll
    },
  },
  date: {
    adapter: DayJsAdapter,
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light,
      dark,
    },
    // colors to be available for color grading like rgb(var(--v-theme-primary-lighten-1))
    variations: {
      colors: ['primary', 'secondary', 'background', 'surface', 'on-surface', 'surfaceVariant', 'outline', 'blue', 'blue-grey'],
      lighten: 5,
      darken: 4,
    },
  },
});
