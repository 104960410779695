import { cloneDeep, isEqual } from 'lodash';
import { reactive } from 'vue';

import documentService, { Document } from '@/case-detail/subviews/document/services/document.service';
import {
  defaultDocumentFilterValues,
  DocumentFilterKey,
  documentFilters,
  DocumentFilterTypes,
} from '@/case-detail/subviews/documents-list/services/filter/document.filters';
import $a from '@/common/services/analytics/analytics';

type FiltersState = {
  [K in DocumentFilterKey]: DocumentFilterTypes[K];
};

class DocumentFilterService {
  state: FiltersState;

  FILTERS = documentFilters;

  constructor() {
    this.state = reactive(cloneDeep(defaultDocumentFilterValues));
  }

  currentFilterValue<K extends DocumentFilterKey>(filterKey: K): DocumentFilterTypes[K] {
    return this.state[filterKey];
  }

  allCurrentFilterValues() {
    return this.state;
  }

  isFilterActive(filterKey: DocumentFilterKey) {
    return !isEqual(defaultDocumentFilterValues[filterKey], this.state[filterKey]);
  }

  activeFiltersCount() {
    let count = 0;
    let dateFilters = false;

    for (const filterKey of Object.keys(this.state)) {
      // if only filterMode is on but no min/max date set, we assume date filtering is off
      if (filterKey === 'filterMode') {
        continue;
      }

      // count it as single filter
      if ((filterKey === 'minDate' || filterKey === 'maxDate') && this.isFilterActive(filterKey)) {
        dateFilters = true;
        continue;
      }

      if (this.isFilterActive(filterKey as DocumentFilterKey)) {
        count++;
      }
    }

    return count + (dateFilters ? 1 : 0);
  }

  isAnyFilterActive() {
    return this.activeFiltersCount() > 0;
  }

  setFilter<T extends DocumentFilterKey>(filterKey: T, value: DocumentFilterTypes[T]) {
    this.setFilters({ [filterKey]: value });
  }

  setDateFilters(min: number, max: number, mode = this.state.filterMode) {
    this.setFilters({
      minDate: min,
      maxDate: max,
      filterMode: mode,
    });
  }

  clearDateFilters(props?: ('minDate' | 'maxDate' | 'filterMode' | 'showMissingDate')[]) {
    const toClear = props ?? ['minDate', 'maxDate', 'filterMode', 'showMissingDate'];
    this.clearFilters(toClear);
  }

  // convenient way to apply several filters at the same time
  // filters: { filterKey: value, ... }
  setFilters(filters: Partial<DocumentFilterTypes>) {
    let clearOnly = true;
    for (const [key, val] of Object.entries(filters)) {
      if (!isEqual(defaultDocumentFilterValues[key as DocumentFilterKey], val)) {
        clearOnly = false;
      }
    }

    if (clearOnly) {
      this.clearFilters(Object.keys(filters) as DocumentFilterKey[]);
      return;
    }

    // if setting any filter, we assume it could need a badge
    if (documentService.getListView() === 'MINIMAL') {
      documentService.setListView('CARD');
    }

    for (const [filterKey, value] of Object.entries(filters)) {
      // @ts-expect-error typing is tricky here
      this.state[filterKey] = value;
    }

    documentService.updateFilteredDocuments();
    documentService.setDuplicateReviewDocuments(null);
  }

  clearFilter(filterKey: DocumentFilterKey) {
    this.clearFilters([filterKey]);
  }

  // convenient way to clear several filters at the same time
  // filterKeys: if unefined, all filters will be cleared
  clearFilters(filterKeys?: DocumentFilterKey[]) {
    $a.l($a.e.DOCLIST_FILTER_CLEAR);

    const keys = filterKeys ?? Object.keys(defaultDocumentFilterValues);
    for (const filterKey of keys) {
      // @ts-expect-error typing is tricky here
      this.state[filterKey] = cloneDeep(defaultDocumentFilterValues[filterKey]);
    }

    documentService.updateFilteredDocuments();
    documentService.setDuplicateReviewDocuments(null);
  }

  getFilteredDocuments(documents: Document[], ignoredFilters: DocumentFilterKey[] = []) {
    return documents.filter((document) => {
      // NOTE(mba): for folders and ref we need to check the duplicates as well
      document.duplicateMatched = false;

      // NOTE(ndv): do not consider deleted documents if the deleted filter is not active.
      const filters = this.state;
      if (!filters.deleted && document?.status?.startsWith('DELETED')) {
        return false;
      }

      if (!this.isAnyFilterActive()) {
        return true;
      }

      for (const [filterKey, filterMeta] of Object.entries(documentFilters)) {
        if (ignoredFilters.includes(filterKey as DocumentFilterKey)) {
          continue;
        }

        // @ts-expect-error typing is tricky here
        if (!filterMeta.filterFn(document, this.state[filterKey], this.state)) {
          return false;
        }
      }
      return true;
    });
  }

  truncateFilterText(text: string) {
    return text.length > 13 ? `${text.substring(0, 10)}...` : text;
  }
}

export default new DocumentFilterService();
