import { reactive } from 'vue';

import router from '@/app/router';
import { PanelId, PanelMetadata, panels, SlotId } from '@/case-detail/services/detail.view.panels.meta';
import { detailViewRouteService } from '@/case-detail/services/detail.view.route.service';
import { pdftronHelper } from '@/case-detail/subviews/document/services/pdftron.helper';
import { legalCaseAPIClient } from '@/common/clients/legalcase.api.client';
import $a from '@/common/services/analytics/analytics';
import { authService } from '@/common/services/auth/auth.service';
import { broadcastEventBus } from '@/common/services/broadcast.service';
import detachedWindowService from '@/common/services/detached-window.service';
import { API } from '@/common/types/api.types';
import { UUID } from '@/common/types/common.types';

export interface CurrentLegalCase extends API.LegalCase.Response {}

export type ViewId = 'case' | 'docs' | 'collab' | 'tools';

export const viewPanelsMap: Record<ViewId, PanelId[]> = {
  case: ['CaseOverview', 'Copilot', 'WebViewer', 'DocumentList', 'Notebook', 'LegalCaseAccess'],
  docs: [
    'WebViewer',
    'DuplicatesReview',
    'DocumentList',
    'Labels',
    'Authors',
    'Diagnosis',
    'WorkInabilities',
    'Copilot',
    'LegalCaseAccess',
    'Notebook',
  ],
  collab: [
    'WebViewer',
    'DuplicatesReview',
    'DocumentList',
    'Labels',
    'Authors',
    'Diagnosis',
    'WorkInabilities',
    'Copilot',
    'LegalCaseAccess',
    'Notebook',
    'Export',
    'Collaboration',
  ],

  // legali admin-only
  tools: ['Timelines', 'FormData', 'PII', 'Metadata'],
};

export const requiredPanelPerView: Record<ViewId, PanelId[]> = {
  case: ['CaseOverview'],
  docs: ['WebViewer', 'DuplicatesReview'], // OR
  collab: ['DocumentList'],
  tools: [],
};

export type MedInsightsMode = 'diagnoses' | 'workInabilities';

class DetailViewService {
  state: {
    view: ViewId;
    panels: Record<ViewId, PanelId[]>;
    currentLegalCase: CurrentLegalCase | null;
    currentCopilotScope: Record<ViewId, API.Copilot.Scope>;
    currentMedInisightsMode: MedInsightsMode | null;
  };

  panels: Record<PanelId, PanelMetadata>;

  constructor() {
    this.state = reactive({
      view: 'docs',
      panels: { case: [], docs: [], collab: [], tools: [] },
      currentLegalCase: null,
      currentCopilotScope: {
        case: 'LEGALCASE',
        docs: 'DOCUMENT',
        collab: 'LEGALCASE', // just a placeholder
        tools: 'DOCUMENT', // just a placeholder
      },
      currentMedInisightsMode: null,
    });

    this.panels = panels;
  }

  // CURRENT LEGAL CASE

  getCurrentLegalCaseId() {
    // NOTE(dp): `undefined` could be returned as well
    // one example of this is going to big case and quickly navigating back to the list - init load functions will still run and call this method
    // for simplicity `string` type is left to not add stupid checks everywhere
    // BUT (!) if you use this method in init-load-type methods — adding manual check for undefined is good idea
    return this.state.currentLegalCase?.id ?? (router.currentRoute.value.params.caseId as string);
  }

  getCurrentLegalCase() {
    return this.state.currentLegalCase;
  }

  async setCurrentLegalCase(caseId: UUID) {
    if (this.state.currentLegalCase && caseId === this.state.currentLegalCase.id) return;
    this.state.currentLegalCase = await legalCaseAPIClient.fetch(caseId);
  }

  async refreshCurrentLegalCase() {
    if (!this.state.currentLegalCase) return;
    this.state.currentLegalCase = await legalCaseAPIClient.fetch(this.state.currentLegalCase.id);
  }

  async updateCurrentLegalCase(newData: API.LegalCase.CreateUpdateRequest) {
    if (!this.state.currentLegalCase) return;
    await legalCaseAPIClient.update(this.state.currentLegalCase.id, newData);
    this.refreshCurrentLegalCase();
  }

  async toggleCurrentLegalCaseArchive() {
    if (!this.state.currentLegalCase) return;
    const legalCase = this.state.currentLegalCase;
    const newStatus = legalCase.legalCaseStatus === 'OPEN' ? 'ARCHIVED' : 'OPEN';
    await legalCaseAPIClient.updateStatus(legalCase.id, newStatus);
    this.refreshCurrentLegalCase();
  }

  async updateCurrentLegalCaseMetadata(key: API.LegalCase.PiiKey, value: string) {
    if (!this.state.currentLegalCase) return;
    await legalCaseAPIClient.updateMetadata(this.state.currentLegalCase.id, key, value);
    this.refreshCurrentLegalCase();
  }

  // COPILOT SCOPE

  getCurrentCopilotScope(view?: ViewId): API.Copilot.Scope {
    const scope = this.state.currentCopilotScope[view ?? this.state.view];
    if (scope === 'LEGALCASE' && !authService.hasFeature('ENABLE_CASEPILOT')) {
      return 'DOCUMENT';
    }
    if (scope === 'DOCUMENT' && !authService.hasFeature('ENABLE_DOCPILOT')) {
      return 'LEGALCASE';
    }
    return scope;
  }

  setCopilotScope(scope: API.Copilot.Scope) {
    this.state.currentCopilotScope[this.state.view] = scope;
  }

  toggleCopilotScope() {
    this.setCopilotScope(this.getCurrentCopilotScope() === 'DOCUMENT' ? 'LEGALCASE' : 'DOCUMENT');
  }

  // PANELS

  isViewOpen(viewId: ViewId) {
    return this.state.view === viewId;
  }

  getPanelsState() {
    return this.state;
  }

  getOpenPanels(viewId?: ViewId): PanelId[] {
    return [...this.state.panels[viewId ?? this.state.view], ...this.state.panels.tools];
  }

  openView(viewId: ViewId) {
    if (this.state.view === viewId) {
      return;
    }

    if (
      (viewId === 'case' && !authService.hasFeature('ENABLE_MEDINSIGHTS_OVERVIEW')) ||
      (viewId === 'collab' && !authService.hasFeature('ENABLE_EXPORT') && !authService.hasFeature('ENABLE_COLLABORATION'))
    ) {
      return;
    }

    if (viewId === 'case') {
      pdftronHelper.switchMode('case');
    } else {
      pdftronHelper.switchMode('normal');
    }

    this.state.view = viewId;
    detailViewRouteService.persistInQuery({ openView: viewId, openPanels: this.getOpenPanels(viewId) });
  }

  togglePanel(panelId: PanelId) {
    if (this.isPanelOpened(panelId)) {
      this.closePanel(panelId);
    } else {
      this.openPanel(panelId);
    }
  }

  isPanelEnabled(panelId: PanelId) {
    return this.panels[panelId].enabled(authService);
  }

  isPanelOpened(panelId: PanelId, viewId?: ViewId) {
    return this.getOpenPanels(viewId).includes(panelId);
  }

  filterPanelsRelevantToView(panelIds: PanelId[], viewId: ViewId) {
    return panelIds.filter((p) => viewPanelsMap[viewId].includes(p));
  }

  openPanel(panelId: PanelId) {
    if (this.isPanelOpened(panelId)) {
      return;
    }

    const panel = panels[panelId];

    if (panel?.detachable) {
      const windowName = detachedWindowService.getPanelWindowName(panelId);
      const window = detachedWindowService.getWindowReference(windowName);
      if (window) {
        window.focus();
        return;
      }
    }

    this.closeSlot(panel.slot);

    if (viewPanelsMap.tools.includes(panelId)) {
      this.state.panels.tools.push(panelId);
    } else if (viewPanelsMap[this.state.view].includes(panelId)) {
      this.state.panels[this.state.view].push(panelId);
    } else {
      // invalid panels for the view
      return;
    }

    detailViewRouteService.persistInQuery({ openPanels: this.getOpenPanels() });
    $a.l(`CASE_PANEL_OPEN_${panelId.toUpperCase()}`);
  }

  closePanel(panelId: PanelId) {
    if (!this.isPanelOpened(panelId)) {
      return;
    }

    if (viewPanelsMap.tools.includes(panelId)) {
      this.state.panels.tools = this.state.panels.tools.filter((p) => p !== panelId);
    } else {
      this.state.panels[this.state.view] = this.state.panels[this.state.view].filter((p) => p !== panelId);
    }

    if (panelId === 'DuplicatesReview') {
      this.openPanel('WebViewer');
    } else if (panelId === 'DocumentList') {
      // close doc filters as well
      this.closeSlot('DocumentListExtension');
    }

    detailViewRouteService.persistInQuery({ openPanels: this.getOpenPanels() });
    $a.l(`CASE_PANEL_CLOSE_${panelId.toUpperCase()}`);
  }

  closeSlot(slotId: SlotId) {
    const items = Object.entries(this.panels).filter(
      ([, panel]) => panel.slot === slotId || (Array.isArray(panel.slot) && panel.slot.includes(slotId)),
    ) as [PanelId, PanelMetadata][];

    const panelIdsToClose = items.map(([panelId]) => panelId);

    this.state.panels.tools = this.state.panels.tools.filter((p) => !panelIdsToClose.includes(p));
    this.state.panels[this.state.view] = this.state.panels[this.state.view].filter((p) => !panelIdsToClose.includes(p));

    // NOTE(dp): i'd NOT do this; imho makes sense to track only user interations
    for (const panelId of panelIdsToClose) {
      $a.l(`CASE_PANEL_CLOSE_${panelId.toUpperCase()}`);
    }
  }

  initArrangement() {
    const initViewParam = detailViewRouteService.getFromQuery('openView');

    let initPanelsParam = detailViewRouteService.getFromQuery('openPanels') ?? [];
    // handle legacy link when we used to call case overview "CasePilot"
    initPanelsParam = initPanelsParam.map((p) => ((p as string) === 'CasePilot' ? 'CaseOverview' : p));
    // don't allow Duplicates because it won't have originalDocument in duplicates service
    initPanelsParam = initPanelsParam.filter((p) => p !== 'DuplicatesReview');
    // leave enabled panels only
    initPanelsParam = initPanelsParam.filter((p) => panels[p].enabled(authService));

    const initTicketIdParam = detailViewRouteService.getFromQuery('ticketId');

    this.initDefaultArrangement();

    // special case - link to specific ticket
    if (initTicketIdParam) {
      this.openView('collab');
      this.openPanel('Collaboration');
      return;
    }

    // catch invalid state
    if (!initViewParam || !initPanelsParam?.length) {
      return;
    }
    if (!initPanelsParam.every((p) => viewPanelsMap[initViewParam].includes(p) || viewPanelsMap.tools.includes(p))) {
      return;
    }

    // make sure at least 1 required panel in the view is present
    if (requiredPanelPerView[initViewParam].length && !initPanelsParam.some((p) => requiredPanelPerView[initViewParam].includes(p))) {
      initPanelsParam.push(requiredPanelPerView[initViewParam][0]);
    }

    this.openView(initViewParam);
    for (const panel of initPanelsParam) {
      this.openPanel(panel);
    }

    broadcastEventBus.once('DOCUMENT_LOADED_EVENT', () => pdftronHelper.switchMode(initViewParam === 'case' ? 'case' : 'normal'));
  }

  initDefaultArrangement() {
    this.state.panels = {
      case: this.defaultPanelState('case'),
      docs: this.defaultPanelState('docs'),
      collab: this.defaultPanelState('collab'),
      tools: [],
    };
    this.state.view = authService.hasFeature('ENABLE_MEDINSIGHTS_OVERVIEW') ? 'case' : 'docs';
  }

  defaultPanelState(viewId: ViewId): PanelId[] {
    switch (viewId) {
      case 'docs':
        return ['DocumentList', 'WebViewer'];
      case 'case':
        return ['CaseOverview', 'Copilot'];
      case 'collab':
        if (panels.Export.enabled(authService)) {
          return ['Export', 'DocumentList', 'WebViewer'];
        }
        return ['Collaboration', 'DocumentList', 'WebViewer'];
      default:
        return [];
    }
  }

  clear() {
    this.state.currentLegalCase = null;
    this.initArrangement();
  }
}

export default new DetailViewService();
