<template>
  <v-app-bar class="app-bar pl-0 ml-0" elevation="0" color="surface" density="compact" data-testid="header_appBar">
    <v-progress-linear
      v-show="showLoadingState"
      color="primary"
      height="3"
      indeterminate
      rounded
      absolute
      location="bottom"
      data-testid="header_progressBar"
    />

    <router-link
      v-if="authService.state.data"
      v-tippy="$route.name === 'case-list' ? '' : $t('CaseList.title')"
      :to="{
        name: 'case-list',
        params: { tenant: authService.state.data.tenant.canonicalName },
      }"
    >
      <AppLogo />
    </router-link>
    <AppLogo v-else />

    <div v-if="$route.name === 'case-pilot-detached'" class="page-title d-flex items-center preserve-icon-color">
      <span class="mr-1 page-title-text">{{ $t('Copilot.overview') }}</span>
      <div v-if="currentLegalCase" class="mx-2 font-weight-medium">{{ currentLegalCase.displayLabel }}</div>
      <ChipBeta :style="{ position: 'relative', top: '1px' }" />
    </div>

    <CaseDetailIndicator v-if="currentLegalCase && ($route.name === 'case' || $route.name === 'notebook')" />

    <v-spacer />
    <SmartSearchWrapper v-if="currentLegalCase && $route.name === 'case'" />

    <ExplorerSearchInput v-if="$hasFeature('ENABLE_DEMO_FEATURES') && ($route.name === 'case-list' || $route.name === 'explorer')" />

    <v-spacer />

    <TenantMenu v-if="authService.state.isLoggedIn" />

    <!-- Intercom: do not remove id attribute -->
    <v-btn
      v-if="authService.state.isLoggedIn"
      v-tippy="$t('Common.help')"
      class="intercom-trigger"
      icon="mdi-help-circle-outline"
      data-testid="header_helpButton"
      @click="onHelpClick"
    />
    <Notification />
    <AppMenu v-if="authService.state.isLoggedIn" />
    <!-- SHARING SCREEN -->
    <template v-if="$route.name === 'sharing'">
      <v-btn class="mr-5" variant="outlined" color="primary" @click="linkToWebsite">
        {{ $t('Common.learnMore') }}
      </v-btn>
      <v-btn to="/" class="mr-5" variant="flat" color="primary">
        {{ $t('App.Bar.signUp') }}
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import AppLogo from '@/app/app-bar/components/AppLogo.vue';
import AppMenu from '@/app/app-bar/components/AppMenu.vue';
import TenantMenu from '@/app/app-bar/components/TenantMenu.vue';
import Notification from '@/app/notification/Notification.vue';
import appService from '@/app/services/app.service';
import CaseDetailIndicator from '@/case-detail/app-bar/CaseDetailIndicator.vue';
import SmartSearchWrapper from '@/case-detail/search/app-bar/SmartSearchWrapper.vue';
import detailViewService, { CurrentLegalCase } from '@/case-detail/services/detail.view.service';
import ExplorerSearchInput from '@/case-explorer/app-bar/ExplorerSearchInput.vue';
import { legalCaseService } from '@/case-list/services/legalcase.service';
import ChipBeta from '@/common/components/ChipBeta.vue';
import { authService } from '@/common/services/auth/auth.service';

export default defineComponent({
  components: {
    AppMenu,
    TenantMenu,
    Notification,
    SmartSearchWrapper,
    ExplorerSearchInput,
    CaseDetailIndicator,
    AppLogo,
    ChipBeta,
  },
  data() {
    return {
      authService,
    };
  },
  computed: {
    showLoadingState(): boolean {
      // NOTE(dp): this solves the issue of progress disappearing if the user changes case list persisted in URI filters while cases are still loading
      const caseListLoading = this.$route.name === 'case-list' && legalCaseService.state.status === 'LOADING';
      return appService.isLoading() || caseListLoading;
    },
    currentLegalCase(): CurrentLegalCase | null {
      return detailViewService.getCurrentLegalCase();
    },
  },
  watch: {
    $route(to, from) {
      if (to.name && from.name && appService.state.appError) {
        // There is a chance the error is gone after route update
        // However, just cleaning up appError here is dangerous, especially if route name is the same (router reuses components' instances)
        // So here we give it a chance to clean up by refreshing the page
        window.location.reload();
      }
    },
  },
  methods: {
    linkToWebsite() {
      window.open('https://legal-i.ch');
    },
    onHelpClick() {
      this.$a.l(this.$a.e.APP_HELP);
    },
  },
});
</script>

<style scoped>
.app-bar {
  border-bottom: 1px solid rgb(var(--v-theme-outline));
}

.app-bar :deep(.v-toolbar__content) {
  z-index: inherit;
  padding: 0 1rem;
}
</style>
