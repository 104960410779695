/* eslint no-underscore-dangle: "off" */
// FIXME(mba): should be #, but not in class context

import search from 'approx-string-match';

import detailViewService from '@/case-detail/services/detail.view.service';
import documentApiService from '@/case-detail/subviews/document/services/document.api.service';
import documentService from '@/case-detail/subviews/document/services/document.service';
import pageReadService from '@/case-detail/subviews/document/services/pageread.service';
import { authService } from '@/common/services/auth/auth.service';
import fileService from '@/common/services/file.service';
import preferencesService from '@/common/services/preferences.service';

const ViewMode = {
  Normal: 'normal',
  Case: 'case',
  DuplicateReview: 'duplicates',
  ExportView: 'export',
};

const allViewElements = [
  // buttons at the right side of header
  // before divider
  'searchButton',
  'splitButton',
  'addFreeCommentButton',
  // after divider
  'customDownloadButton',
  'customPrintButton',
  'customHocrDownloadButton',
  'customGoogleDocumentAiDownloadButton',
  'backToDuplicatesReviewButton', // close btn when in duplicates comparison (diff) mode

  'textPopup', // annotations popup for selection inside doc
  'closeDocumentViewButton',
];

const viewElementsByMode = {
  [ViewMode.Normal]: [
    'searchButton',
    'splitButton',
    'addFreeCommentButton',

    'customDownloadButton',
    'customPrintButton',
    'customHocrDownloadButton',
    'customGoogleDocumentAiDownloadButton',

    'textPopup',
  ],
  [ViewMode.Case]: [
    'closeDocumentViewButton', // enable close btn in Case Overview only
  ],
  [ViewMode.ExportView]: [
    'searchButton',

    'customDownloadButton',
    'customPrintButton',
    'customHocrDownloadButton',
    'customGoogleDocumentAiDownloadButton',
  ],
  [ViewMode.DuplicateReview]: ['backToDuplicatesReviewButton'],
};

const pdftronHelper = {
  element: undefined,
  webViewer: undefined,
  docViewer: undefined,
  vueInstance: undefined,
  isLoaded: false,
  viewMode: ViewMode.Normal,

  setInstance(wv, e, v) {
    this.webViewer = wv;
    this.element = e;
    this.docViewer = wv.Core.documentViewer;
    this.vueInstance = v;
    this.ignoreNextRotationEvent = false;
    this.ignoreNextFitModeEvent = false;
  },

  clear() {
    this.vueInstance = undefined;
    this.element = undefined;
    this.webViewer = undefined;
    this.docViewer = undefined;
    this.isLoaded = false;
  },

  /* LOAD DOCUMENTS */

  // Used in export to preview
  async showPdf(url) {
    this.webViewer.UI.loadDocument(url, {
      customHeaders: {
        Authorization: `Bearer ${await authService.getToken()}`,
        'X-Tenant-ID': authService.state.data?.tenant.id,
      },
    });
  },

  // document list
  async loadDocument(document, page, mode = null) {
    if (!this.webViewer) {
      return null;
    }

    mode = mode ?? detailViewService.isViewOpen('case') ? ViewMode.Case : ViewMode.Normal;
    this.switchMode(mode);

    // setup loaded event listener, once
    const documentLoadedPromise = new Promise((resolve) => {
      this.docViewer.addEventListener(
        'documentLoaded',
        () => {
          pageReadService.logRead(document, 1);
          pdftronHelper.isLoaded = true;
          if (document.metadata.PDF_ROTATION.value) {
            const rot = parseInt(document.metadata.PDF_ROTATION.value, 10);
            let pageRotation;
            switch (rot) {
              case 90:
                pageRotation = this.webViewer.Core.PageRotation.E_90;
                break;
              case 180:
                pageRotation = this.webViewer.Core.PageRotation.E_180;
                break;
              case 270:
                pageRotation = this.webViewer.Core.PageRotation.E_270;
                break;
              default:
                pageRotation = this.webViewer.Core.PageRotation.E_0;
            }
            this.ignoreNextRotationEvent = true;
            this.webViewer.Core.documentViewer.setRotation(pageRotation);
          }

          this.webViewer.Core.documentViewer.displayPageLocation(page || 1, 0, 0);
          documentService.setFirstDocumentLoaded(true);
          documentService.setDuplicateReviewDocuments(null);
          this.webViewer.UI.setLayoutMode(this.webViewer.UI.LayoutMode.Continuous);

          const fitMode = preferencesService.state.documentViewerPreferences.pdftronFitMode;
          this.webViewer.UI.setFitMode(fitMode);

          resolve();
        },
        { once: true },
      );
    });

    // Load the Document
    // NOTE(mba): pdftron always triggers a fitMode Event on load of a new document
    this.ignoreNextFitModeEvent = true;

    const blob = await this._loadPdfCached(document.fileUri);
    await this.webViewer.UI.loadDocument(blob, { extension: 'pdf' });

    return documentLoadedPromise;
  },

  // NOTE(mba): private, should be #loadPDF but not in a class
  async _loadPdfCached(fileUri) {
    const cache = await caches.open('pdfs');
    const response = await cache.match(`/${fileUri}`);
    if (response) {
      return await response.blob();
    }
    const token = await authService.getToken();
    const url = fileService.getUrlSync(fileUri);

    const fetchResponse = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Tenant-ID': authService.state.data?.tenant.id,
      },
    });
    const responseForCache = fetchResponse.clone();
    const blob = await fetchResponse.blob();
    await cache.put(`/${fileUri}`, responseForCache);
    return blob;
  },

  /* load two documents for duplicate comparision */
  async compareDocuments(document1, document2) {
    pdftronHelper.switchMode(ViewMode.DuplicateReview);

    const { PDFNet } = this.webViewer.Core;
    await PDFNet.initialize();

    const newDoc = await PDFNet.PDFDoc.create();
    await newDoc.lock();

    const [ab1, ab2] = await Promise.all([
      this._loadPdfCached(document1.fileUri).then((blob) => blob.arrayBuffer()),
      this._loadPdfCached(document2.fileUri).then((blob) => blob.arrayBuffer()),
    ]);

    const doc1 = await PDFNet.PDFDoc.createFromBuffer(ab1);
    const doc2 = await PDFNet.PDFDoc.createFromBuffer(ab2);
    await newDoc.appendTextDiffDoc(doc1, doc2);
    await newDoc.unlock();
    this.webViewer.UI.loadDocument(newDoc);
  },

  /** * UI STATE ** */
  switchMode(mode) {
    if (!this.webViewer) {
      return;
    }
    // NOTE: no check for matching to current mode so initial switch could be possible
    const toEnable = allViewElements.filter((el) => viewElementsByMode[mode].includes(el));
    const toDisable = allViewElements.filter((el) => !viewElementsByMode[mode].includes(el));
    this.webViewer.UI.enableElements(toEnable);
    this.webViewer.UI.disableElements(toDisable);

    this.viewMode = mode;
  },

  setDefaultFitMode(fitMode) {
    if (this.ignoreNextFitModeEvent) {
      this.ignoreNextFitModeEvent = false;
      return;
    }

    preferencesService.updatePreferences({ documentViewerPreferences: { pdftronFitMode: fitMode } });
  },

  getCurrentPage() {
    return this.webViewer.Core.documentViewer.getCurrentPage();
  },

  /** ** SEARCH **** */

  // public
  async search(pageKeywords, callback) {
    // clear, if any
    this.docViewer.clearSearchResults();

    this.docViewer.setSearchHighlightColors({
      searchResult: 'rgba(255, 255, 0, 0.4)',
    });

    const allResults = [];
    for (const pageResult of pageKeywords) {
      if (pageResult.keywords.length > 0) {
        const results = [];
        for (const keyword of pageResult.keywords) {
          results.push(...(await this.searchPdf(keyword, pageResult.page)));
        }

        if (results.length >= pageResult.count) {
          allResults.push(...results);
          this.docViewer.displayAdditionalSearchResults(allResults);
          callback(results);
        } else {
          pageResult.annotations = await this.fuzzySearch(pageResult);
          callback([pageResult]);
        }
      }
    }
  },
  escapeRegExp(text) {
    return text.replace(/[()]/g, '\\$&');
  },
  jumpToSearchResult(searchResult) {
    if (!searchResult.page) {
      this.docViewer.setActiveSearchResult(searchResult);
    } else {
      this.docViewer.setCurrentPage(searchResult.page);
    }
  },
  async searchPdf(term, page) {
    return await new Promise((resolve, reject) => {
      const { Mode } = this.webViewer.Core.Search;
      /* eslint-disable-next-line */
      const mode = Mode.HIGHLIGHT | Mode.AMBIENT_STRING | Mode.REGEX;
      const { ResultCode } = this.webViewer.Core.Search;

      const allResults = [];
      const cleanedTerm = `(?:^|\\s|$|\\b)${this.escapeRegExp(term).replaceAll(/\s/gi, '.*?')}(?:^|\\s|$|\\b)`;
      this.docViewer.textSearchInit(cleanedTerm, mode, {
        fullSearch: true,
        startPage: page,
        endPage: page,
        onDocumentEnd: () => {
          resolve(allResults);
        },
        onError: (err) => {
          reject(err);
        },
        onResult: (result) => {
          if (result.resultCode === ResultCode.FOUND) {
            allResults.push(result);
          }
        },
      });
    });
  },
  async fuzzySearch(pageResult) {
    const annotations = [];
    for (const keyword of pageResult.keywords) {
      const pageText = await this.docViewer.getDocument().loadPageText(pageResult.page);
      annotations.push(...(await this.fuzzySearchPage(keyword, pageResult.page, pageText)));
    }
    return annotations;
  },
  async fuzzySearchPage(keyword, pageIndexOne, pageText) {
    const annotations = [];
    const noOfSpaces = keyword.split(' ').length - 1;
    const matches = search(pageText, keyword, 15 + noOfSpaces /* max errors */);
    if (matches.length > 0) {
      for (const match of matches) {
        if (match.start >= match.end) {
          continue;
        }

        const { Annotations } = this.webViewer.Core;
        const annotation = new Annotations.TextHighlightAnnotation();
        annotation.PageNumber = pageIndexOne;
        annotation.Quads = await this.docViewer.getDocument().getTextPosition(pageIndexOne, match.start, match.end);

        annotation.ReadOnly = true;
        annotation.Printable = false;

        annotations.push(annotation);
      }
    }
    return annotations;
  },

  /** ROTATION ** */
  changeRotation(rotation) {
    if (!this.ignoreNextRotationEvent) {
      if (this.vueInstance.document?.metadata) {
        this.vueInstance.document.metadata.PDF_ROTATION.value = rotation;
      } else {
        this.vueInstance.document.metadata = { PDF_ROTATION: rotation };
      }
      documentApiService.changeRotation(this.vueInstance.document.caseId, this.vueInstance.document.id, rotation);
    }
    this.ignoreNextRotationEvent = false;
  },

  /** ** NAVIGATION *** */
  prevPage() {
    if (this.docViewer.getCurrentPage() === 1) {
      documentService.prevDocument(true);
    } else {
      this.docViewer.displayPageLocation(this.docViewer.getCurrentPage() - 1, 0, 0);
    }
  },
  nextPage() {
    if (this.docViewer.getCurrentPage() + 1 > this.docViewer.getPageCount()) {
      documentService.nextDocument();
    } else {
      this.docViewer.displayPageLocation(this.docViewer.getCurrentPage() + 1, 0, 0);
    }
  },
  goToPage(page) {
    this.docViewer.displayPageLocation(page, 0, 0);
  },
};

export { pdftronHelper, ViewMode };
