<template>
  <v-lazy :min-height="90" :options="{ threshold: 0.2 }">
    <v-row :class="{ ghostly: sourceFile.deleted }">
      <v-col class="d-flex align-center" cols="1">
        <div class="mx-auto">
          <v-icon v-if="sourceFile.deleted"> mdi-trash-can-outline </v-icon>
          <template v-else>
            <v-icon v-if="sourceFile.status === 'READY'" color="success"> mdi-checkbox-marked-circle-outline </v-icon>
            <v-icon v-else-if="sourceFile.status === 'ERROR' && $isLegaliAdmin()" color="error"> mdi-reload-alert </v-icon>
            <v-icon v-else-if="sourceFile.status === 'SEGMENTATION_REVIEW' && $isLegaliAdmin()"> mdi-checkbox-multiple-marked-outline </v-icon>

            <v-icon v-else> mdi-sync </v-icon>
          </template>
        </div>
      </v-col>
      <v-col class="text-caption pt-5 d-flex align-center" cols="3" data-testid="row_info">
        <div :style="{ maxWidth: '100%' }">
          <a
            v-if="$isLegaliAdmin() || $hasRole('TENANT_ADMIN_ROLE')"
            data-testid="row_download"
            @click="download(sourceFile.originalFileUri, normalizePdfFilename(sourceFile.uploadFilename || 'datei.pdf'))"
            >{{ sourceFile.uploadFilename || $t('Common.unknown') }}</a
          >
          <span v-else>
            {{ sourceFile.uploadFilename || $t('Common.unknown') }}
          </span>
          <br />
          {{ $t('CaseList.FileManager.uploadedOn', [formatToLocale(sourceFile.created)]) }}<br />
        </div>
      </v-col>
      <v-col class="text-caption pt-5 d-flex flex-column justify-center" cols="3" data-testid="row_document_folders">
        <div>
          {{ $tc('CaseList.FileManager.containsNDocuments', documentsCount) }}<br />
          <div v-if="sourceFile.documentFolders">
            <div v-for="(documentIds, folderName) in sourceFile.documentFolders" :key="folderName">
              {{ folderService.getFolderText(folderName) }}: {{ documentIds.length }}
            </div>
          </div>
        </div>
      </v-col>
      <v-col v-if="showAutocomplete" cols="5" class="text-caption pt-5">
        <span class="d-flex justify-content-start">{{ $t('CaseList.FileManager.changeFolder') }}</span>
        <div class="d-flex align-self-end">
          <v-autocomplete
            v-model="newFolder"
            :items="folderService.getLocalizedFolders()"
            :no-data-text="$t('Common.notFound')"
            data-testid="row_select"
            :readonly="sourceFile.deleted"
            :prepend-icon="sourceFile.folder ? '' : 'mdi-alert-circle-outline'"
            hide-details
            class="flex-grow-1"
            @update:model-value="edited = true"
            @keydown.stop
          />
          <div class="d-flex align-end ml-4">
            <v-btn
              v-blur
              size="small"
              class="mr-2"
              color="primary"
              variant="flat"
              :disabled="!newFolder || newFolder.length === 0"
              data-testid="row_save"
              @click.stop="moveFolderAction"
            >
              <span> {{ $t('CaseList.FileManager.moveDocuments') }} </span>
            </v-btn>
            <v-btn size="small" style="cursor: pointer" variant="outlined" @click.stop="showAutocomplete = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col v-if="!showAutocomplete" cols="4" class="text-caption pt-5">
        <template v-if="sourceFile.status === 'ERROR' && sourceFile.debugInfo && $isLegaliAdmin()">
          <v-btn v-tippy="$t('CaseList.FileManager.details')" color="error" variant="outlined" size="small" @click="$emit('show-panel', sourceFile)">
            <v-icon start> mdi-reload-alert </v-icon>
            {{ sourceFile.debugInfo.status }}
          </v-btn>

          <v-btn
            v-tippy="$t('CaseList.FileManager.restartFile', [sourceFile.debugInfo.status])"
            icon
            class="ml-1"
            density="comfortable"
            @click="requestTask(sourceFile.debugInfo.status as API.SourceFile.Status)"
          >
            <v-icon color="warning"> mdi-restart </v-icon>
          </v-btn>

          <v-btn v-tippy="$t('CaseList.FileManager.auditHistory')" icon class="ml-1" density="comfortable" @click="showHistory(sourceFile.id)">
            <v-icon color="warning"> mdi-pipe-leak </v-icon>
          </v-btn>
        </template>

        <v-btn
          v-else-if="sourceFile.status === 'SEGMENTATION_REVIEW' && $isLegaliAdmin()"
          :to="{
            name: 'split',
            params: {
              tenant: authService.state.data?.tenant.canonicalName,
              caseId: legalCase.id,
              sourceFileId: sourceFile.id,
            },
          }"
          variant="flat"
          color="primary"
        >
          <v-icon start> mdi-checkbox-multiple-marked-outline </v-icon>
          {{ $t('CaseList.FileManager.reviewSegmentation') }}
        </v-btn>
        <div v-else-if="sourceFile.status !== 'READY'" class="text-body-2">
          {{ $t('CaseList.processing') }}
          <v-progress-linear class="mt-2" indeterminate rounded />
        </div>

        <div v-if="$isLegaliAdmin()" class="text-no-wrap">
          {{ sourceFile.id }} <br />
          {{ sourceFile.extractorVersions['BASE_OCR'] || $t('CaseList.FileManager.noOcr') }}
          <span v-if="currentPipeline"><br />{{ currentPipeline }}</span>
          <a v-if="sourceFile.processingInfo.processingId" @click="cancelTask()"><br />{{ $t('CaseList.FileManager.cancelPipelineTask') }} </a>
        </div>
      </v-col>
      <v-col v-if="!showAutocomplete" class="d-flex justify-end align-baseline" cols="1">
        <v-icon
          v-if="$isLegaliAdmin() && sourceFile.metadata.INTEGRATION"
          v-tippy="{ content: JSON.stringify(JSON.parse(sourceFile.metadata.INTEGRATION), null, 2), placement: 'top' }"
        >
          mdi-redhat
        </v-icon>
        <l-menu location="bottom end">
          <template #activator="{ props: menuProps }">
            <v-btn
              v-tippy="$t('CaseList.FileManager.moreDetails')"
              density="comfortable"
              icon="mdi-dots-vertical"
              data-testid="row_menu"
              variant="text"
              v-bind="menuProps"
            />
          </template>
          <v-list density="compact" class="pa-0">
            <v-list-item
              data-testid="row_menu_folders"
              @click="
                showAutocomplete = !showAutocomplete;
                newFolder = null;
              "
            >
              <v-list-item-title>{{ $t('CaseList.FileManager.moveDocuments') }}</v-list-item-title>
            </v-list-item>
            <v-divider />
            <template v-if="$isLegaliAdmin()">
              <div class="v-list-subheader v-list-subheader__text d-flex mt-2">
                <div>legal-i Admin</div>
                <ChipAdmin class="ml-1" />
              </div>
              <v-list-subheader>{{ $t('CaseList.FileManager.downloadFile') }}</v-list-subheader>
              <v-list-item @click="download(sourceFile.originalFileUri, legalCase.displayLabel.replace(' ', '-').toLowerCase() + '-original.pdf')">
                <v-list-item-title>{{ $t('CaseList.FileManager.fileMenuOriginal') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="download(sourceFile.ocrFileUri, legalCase.displayLabel.replace(' ', '-').toLowerCase() + '-ocr.pdf')">
                <v-list-item-title>{{ $t('CaseList.FileManager.fileMenuOCR') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="download(sourceFile.extractedTextFileUri, legalCase.displayLabel.replace(' ', '-').toLowerCase() + '-text.json')">
                <v-list-item-title>{{ $t('CaseList.FileManager.fileMenuTextJSON') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="copySegmentation(sourceFile.segmentation)">
                <v-list-item-title>{{ $t('CaseList.FileManager.copySegmentation') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="showHistory(sourceFile.id)">
                <v-list-item-title>{{ $t('CaseList.FileManager.auditHistory') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="del()">
                <v-list-item-title>{{ $t('CaseList.FileManager.deleteFile') }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </l-menu>
        <l-menu v-if="$isLegaliAdmin()" location="bottom end">
          <template #activator="{ props: menuProps }">
            <v-btn v-tippy="'Pipeline Tasks'" icon="mdi-pipe-valve" density="comfortable" variant="text" v-bind="menuProps" />
          </template>
          <v-list density="compact" class="pa-0">
            <v-list-subheader>Start Task</v-list-subheader>
            <v-list-item @click="requestTask('OCR')">
              <v-list-item-title>OCR</v-list-item-title>
            </v-list-item>
            <v-list-item @click="requestTask('SEGMENTATION')">
              <v-list-item-title>SEGMENTATION</v-list-item-title>
            </v-list-item>
            <v-list-item @click="requestTask('SPLITTING')">
              <v-list-item-title>SPLITTING</v-list-item-title>
            </v-list-item>
            <v-list-item @click="requestTask('INDEXING')">
              <v-list-item-title>INDEXING</v-list-item-title>
            </v-list-item>
            <v-list-item @click="requestTask('EXTRACTION')">
              <v-list-item-title>EXTRACTION</v-list-item-title>
            </v-list-item>
            <v-list-item @click="requestTask('EXTRACTION', true)">
              <v-list-item-title>EXTRACTION (force all)</v-list-item-title>
              <v-list-item-subtitle>Resets all extractors on the sourcefile</v-list-item-subtitle>
            </v-list-item>
            <v-list-item @click="requestTask('ANALYZING')">
              <v-list-item-title>ANALYZER</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="$hasFeature('ENABLE_MEDINSIGHTS_OVERVIEW')" @click="requestTask('ANSWERING')">
              <v-list-item-title>ANSWER</v-list-item-title>
            </v-list-item>

            <v-list-item @click="requestTask('READY')">
              <v-list-item-title>READY</v-list-item-title>
              <v-list-item-subtitle>Forced, take care!</v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </l-menu>
        <PipelineHistory :source-file-id="historySourceFileId" @close="historySourceFileId = null" />
        <v-dialog v-model="segmentationDialog" max-width="290" @close="segmentationInfo = null" @click:outside="segmentationInfo = null">
          <v-card>
            <v-card-text>
              <v-textarea ref="segmentationTextArea" :model-value="segmentationInfo" hint="Segmentation" @focus="$event.target.select()" />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-lazy>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import appService from '@/app/services/app.service';
import PipelineHistory from '@/case-list/components/PipelineHistory.vue';
import { pipelineOrchestratorService } from '@/case-list/services/pipeline.orchestrator.service';
import { sourceFileClient } from '@/common/clients/sourcefile.client';
import ChipAdmin from '@/common/components/ChipAdmin.vue';
import { authService } from '@/common/services/auth/auth.service';
import { formatToLocale } from '@/common/services/date.utils';
import fileService from '@/common/services/file.service';
import { folderService } from '@/common/services/folder.service';
import { updateDocumentsFolder } from '@/common/services/folder.utils';
import { API } from '@/common/types/api.types';
import { BaseLegalCaseResponse } from '@/common/types/api-types/legalcase.api.types';
import { UUID } from '@/common/types/common.types';

export default defineComponent({
  components: { PipelineHistory, ChipAdmin },

  props: {
    legalCase: {
      type: Object as PropType<BaseLegalCaseResponse>,
      required: true,
    },
    sourceFile: {
      type: Object as PropType<API.SourceFile.Response>,
      required: true,
    },
  },

  emits: ['refresh', 'show-panel'],

  data() {
    return {
      edited: false,
      historySourceFileId: null as UUID | null,
      segmentationDialog: false,
      segmentationInfo: null as string | null,
      authService,
      folderService,
      showAutocomplete: false,
      newFolder: null as string | null,
    };
  },

  computed: {
    documentsCount() {
      if (this.sourceFile.segmentation) {
        return Object.values(this.sourceFile.segmentation.segmentation).filter((value) => value).length;
      }
      return 0;
    },
    currentPipeline() {
      if (this.sourceFile.status === 'ERROR' || this.sourceFile.status === 'READY') {
        return '';
      }
      let pipelineString = `P: ${this.sourceFile.status}`;
      pipelineString += this.sourceFile.processingInfo.processingId ? ', RUNNING' : ', STOPPED';

      // only add date if processingId is set (i.e. process is running)
      if (this.sourceFile.processingInfo.processingId) {
        pipelineString += `, ${formatToLocale(this.sourceFile.processingInfo.processingStarted, 'L LT')}`;
      }

      return pipelineString;
    },
  },

  methods: {
    formatToLocale,
    async moveFolderAction() {
      if (this.sourceFile.deleted || !this.newFolder) {
        return;
      }
      const documentIds = Object.values(this.sourceFile.documentFolders).flat();
      await updateDocumentsFolder(this.sourceFile.legalCaseId, documentIds, this.newFolder);
      this.showAutocomplete = false;
      this.newFolder = null;
      this.$emit('refresh');
      appService.info(this.$t('CaseList.FileManager.changesSaved'));
      this.$a.l(this.$a.e.FILEMANAGER_MOVE);
    },
    async del() {
      appService.confirm(
        this.$t('CaseList.FileManager.deleteFileConfirmationTitle'),
        this.$t('CaseList.FileManager.deleteFileConfirmationMessage', [this.sourceFile.uploadFilename]),
        this.$t('CaseList.FileManager.deleteFIleConfirmationAction'),
        async () => {
          await sourceFileClient.delete(this.sourceFile.legalCaseId, this.sourceFile.id);
          this.$emit('refresh');
        },
        'warning',
        this.$t('Common.delete'),
      );
      this.$a.l(this.$a.e.FILEMANAGER_DELETE);
    },

    async requestTask(status: API.SourceFile.Status, forced = false) {
      await pipelineOrchestratorService.requestTask(this.sourceFile.id, status, forced);
      this.$emit('refresh');
      this.$a.l(this.$a.e.FILEMANAGER_PIPELINE);
    },

    async cancelTask() {
      await pipelineOrchestratorService.cancelTask(this.sourceFile.id);
      this.$emit('refresh');
      this.$a.l(this.$a.e.FILEMANAGER_PIPELINE);
    },

    showHistory(sourceFileId: UUID) {
      this.historySourceFileId = sourceFileId;
      this.$a.l(this.$a.e.FILEMANAGER_AUDIT);
    },

    download(fileUri: string, filename: string) {
      appService.info(this.$t('Common.File.preparingDownload'));
      fileService.download(fileUri, filename);
      this.$a.l(this.$a.e.FILEMANAGER_DOWNLOAD);
    },

    copySegmentation(segmentation: API.SourceFile.Segmentation) {
      if (!segmentation) {
        appService.info('No segmentation info available');
        return;
      }
      this.segmentationInfo = JSON.stringify(segmentation.segmentation);
      this.segmentationDialog = true;
      this.$a.l(this.$a.e.FILEMANAGER_SEGMENTATION);
    },
    normalizePdfFilename(filename: string) {
      return (filename.lastIndexOf('.') !== -1 ? filename.substring(0, filename.lastIndexOf('.')) : filename).concat('.', 'pdf');
    },
  },
});
</script>

<style scoped></style>
