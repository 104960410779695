import { reactive } from 'vue';

import appService from '@/app/services/app.service';
import { PanelId } from '@/case-detail/services/detail.view.panels.meta';
import detailViewService from '@/case-detail/services/detail.view.service';
import { BroadcastEvent } from '@/common/services/broadcast.events';
import { broadcastEventBus } from '@/common/services/broadcast.service';
import { safeUnwrap } from '@/common/services/common.utils';
import { ScreenDetailsRoot } from '@/common/types/ScreenDetails.types';

class DetachedWindowService {
  state: { detachedWindows: Window[] };

  constructor() {
    this.state = reactive({
      detachedWindows: [],
    });

    broadcastEventBus.subscribe('DETACHED_WINDOW_CLOSED_EVENT', this.handleDetachedWindowClosedEvent.bind(this));
    broadcastEventBus.subscribe('CASE_CHANGED_EVENT', this.handleCaseChangedEvent.bind(this));
  }

  async detachWindow(url: string, windowName: string) {
    const windowReference = await this.checkExistingWindow(url, windowName);
    if (windowReference) {
      this.addDetachedWindow(windowReference);
      return true;
    }
    return false;
  }

  async checkExistingWindow(url: string, windowName: string) {
    let windowFeatures = 'toolbar=0,location=0,menubar=0';

    // check for window management api availability (will be False in Firefox and Safari as of October 2024)
    if ('getScreenDetails' in window) {
      // @ts-expect-error new api
      const [, screenDetails] = await safeUnwrap<ScreenDetailsRoot>(window.getScreenDetails());
      if (screenDetails) {
        if (screenDetails.screens.length > 1) {
          // make use of second screen
          const screen = screenDetails.screens.find((s) => s !== screenDetails.currentScreen)!;
          windowFeatures += `,left=${screen.availLeft},top=${screen.availTop},width=${screen.availWidth},height=${screen.availHeight}`;
        } else {
          // make it 50% of the current screen, stick to the right
          const screen = screenDetails.currentScreen;
          windowFeatures += `,left=${screen.availLeft + Math.floor(screen.availWidth / 2)},top=${screen.availTop},width=${Math.floor(screen.availWidth / 2)},height=${screen.availHeight}`;
        }
      }
    }

    const existingWindow = window.open('', windowName, windowFeatures);
    if (existingWindow?.location.href === 'about:blank') {
      existingWindow.location.href = url;
    }
    return existingWindow;
  }

  getWindowReference(windowName: string) {
    const detachedWindows = this.getOpenDetachedWindows();
    return detachedWindows.find((window: Window) => window.name.includes(windowName));
  }

  addDetachedWindow(window: Window) {
    this.state.detachedWindows.push(window);
  }

  getOpenDetachedWindows() {
    if (this.state.detachedWindows.length > 0) {
      this.state.detachedWindows = this.state.detachedWindows.filter((window) => !window.closed);
    }
    return this.state.detachedWindows;
  }

  getPanelWindowName(panelId: PanelId) {
    if (panelId === 'CaseOverview') {
      const caseId = detailViewService.getCurrentLegalCase();
      const { contextId } = appService.state;
      return `insightsWindow_${caseId}_${contextId}`;
    }
    if (panelId === 'Copilot') {
      const currentScope = detailViewService.getCurrentCopilotScope();
      return `copilotWindow_${currentScope}`;
    }
    return panelId.toLowerCase();
  }

  getPanelWindowPath(panelId: PanelId) {
    if (panelId === 'CaseOverview') {
      return 'case-pilot';
    }
    return panelId.toLowerCase();
  }

  closeAllDetachedWindows() {
    this.state.detachedWindows.forEach((window) => {
      window.close();
    });
    this.state.detachedWindows = [];
  }

  close(windowName: string) {
    const windowReference = this.getWindowReference(windowName);
    if (windowReference) {
      windowReference.close();
    }
  }

  destroy() {
    this.closeAllDetachedWindows();
    broadcastEventBus.unsubscribe('DETACHED_WINDOW_CLOSED_EVENT', this.handleDetachedWindowClosedEvent.bind(this));
    broadcastEventBus.unsubscribe('CASE_CHANGED_EVENT', this.handleCaseChangedEvent.bind(this));
  }

  handleDetachedWindowClosedEvent(event: BroadcastEvent['DETACHED_WINDOW_CLOSED_EVENT']) {
    if (!this.state) {
      return;
    }
    this.state.detachedWindows = this.state.detachedWindows.filter((window) => !window.name.includes(event.windowName));
  }

  handleCaseChangedEvent = () => {
    this.closeAllDetachedWindows();
  };
}

export default new DetachedWindowService();
